import axios from '@/api/axios';

const getAnalyticsTypes = async () => {
  const { data: response } = await axios.get(`/analytics/types`);
  return response;
};

const getAnalytics = async (type, date_from, date_to) => {
  const { data: response } = await axios.get(`/analytics`, {
    params: {
      type,
      date_from,
      date_to,
    },
  });
  return response;
};

export { getAnalyticsTypes, getAnalytics };
