<template>
  <Chart
    class="hide-x-axis"
    :class="{ 'show-x-axis': data.length > 0 && data.length <= 11 }"
    :size="{ width: 705, height: 440 }"
    :data="data"
    :margin="margin"
    :direction="direction"
  >
    <template #layers>
      <Grid strokeDasharray="2,2" />
      <Line :dataKeys="['name', 'pl']" :lineStyle="{ stroke: '#daa14c' }" />
    </template>

    <template #widgets>
      <Tooltip
        color="#daa14c"
        :config="{
          name: { label: 'Дата', color: '#2c333e' },
          pl: { label: 'Кол-во', color: '#2c333e' },
          avg: { hide: true },
          inc: { hide: true },
        }"
      />
    </template>
  </Chart>
</template>

<script>
import { ref } from "vue";
import { Chart, Grid, Line, Tooltip } from "vue3-charts";

export default {
  components: {
    Chart,
    Grid,
    Line,
    Tooltip,
  },
  props: ["data"],
  setup() {
    const direction = ref("horizontal");
    const margin = ref({
      left: 0,
      top: 20,
      right: 20,
      bottom: 0,
    });

    return {
      direction,
      margin,
    };
  },
};
</script>

<style >
.hide-x-axis .layer-axis-x text {
  opacity: 0;
}

.show-x-axis .layer-axis-x text {
  opacity: 1;
}
</style>