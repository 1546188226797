<template>
  <FixedColumnTable>
    <table class="table main-table bordered text-center">
      <thead>
        <tr>
          <th scope="col">№</th>
          <th class="text-start" scope="col">Наименование</th>
          <th scope="col">Количество</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="item.id">
          <td>
            {{ ++index }}
          </td>
          <td class="text-start">{{ item.product_name }}</td>
          <td>{{ item.task_count }}</td>
        </tr>
        <tr v-if="!data?.length">
          <td colspan="9">
            <span class="d-block text-bold my-3">Ничего не найдено!</span>
          </td>
        </tr>
      </tbody>
    </table>
  </FixedColumnTable>
</template>

<script>
import FixedColumnTable from "@/components/ui/FixedColumnTable.vue";

export default {
  components: { FixedColumnTable },
  props: ["data"],
  setup() {
    return {};
  },
};
</script>
