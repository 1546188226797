<template>
  <div
    v-if="analyticsPermissionsOptions.length"
    class="d-flex justify-content-between"
  >
    <div
      class="depot__filters-wrapper"
      style="min-width: 294px; max-width: 294px"
    >
      <div class="depot__filters">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h4 class="depot__filters-title">Фильтр</h4>
        </div>

        <span class="line-separator"></span>

        <div v-if="analyticsPermissionsOptions.length > 1">
          <h5 class="depot__filters-subtitle">Тип</h5>
          <v-select
            class="depot__select long"
            placeholder="Все типы"
            label="title"
            :options="analyticsPermissionsOptions"
            :reduce="(item) => item.id"
            :clearable="false"
            v-model="state.type"
          ></v-select>
        </div>

        <h5 class="depot__filters-subtitle">Дата</h5>
        <DatePicker
          class="admin-dashboard__calendar"
          title-position="left"
          mode="date"
          color="yellow"
          borderless
          transparent
          v-model.range="state.range"
        />
      </div>
    </div>

    <div class="d-flex justify-content-center w-100 mt-4">
      <Kpi v-if="state.type == 'kpi'" :data="state.data" />
      <Popularity v-else-if="state.type == 'popularity'" :data="state.data" />
      <MainGraph v-if="state.type == 'time-consuming'" :data="state.data" />
    </div>
  </div>

  <div v-else-if="isPermissionsLoaded && !analyticsPermissionsOptions?.length">
    <span class="d-block text-bold text-center my-5">Доступ закрыт!</span>
  </div>
</template>

<script>
import { reactive, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";

import { getAnalytics } from "@/api/analytics";
import useLoadingModal from "@/mixins/useLoadingModal";

import Kpi from "@/components/analytics/Kpi.vue";
import MainGraph from "@/components/analytics/MainGraph.vue";
import Popularity from "@/components/analytics/Popularity.vue";

import moment from "moment";
import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";

export default {
  components: { Kpi, MainGraph, Popularity, DatePicker },
  setup() {
    const store = useStore();
    const userPermissions = computed(() => store.getters.getUserPermissions);
    const isPermissionsLoaded = computed(
      () => store.getters.isPermissionsLoaded
    );
    const analyticsPermissions = computed(
      () => userPermissions.value["23"]?.permissions ?? []
    );
    const analyticsPermissionsOptions = computed(() => {
      const res = [];
      if (analyticsPermissions.value["18"])
        res.push({ id: "kpi", title: "Автоматическое выявление КПД" });
      if (analyticsPermissions.value["21"])
        res.push({ id: "popularity", title: "Время-затратность" });
      if (analyticsPermissions.value["23"])
        res.push({ id: "time-consuming", title: "Популярность" });

      return res;
    });
    const activeType = computed(() =>
      analyticsPermissionsOptions.value.length
        ? analyticsPermissionsOptions.value[0]?.id
        : null
    );

    const state = reactive({
      type: activeType.value ?? null,
      range: null,
      data: [],
    });

    const { isDataLoading } = useLoadingModal();

    watch(activeType, () => {
      if (activeType.value != null) state.type = activeType.value;
    });

    const getData = async () => {
      const date_from = state.range?.start
        ? moment(new Date(state.range?.start)).format("YYYY-MM-DD")
        : null;
      const date_to = state.range?.end
        ? moment(new Date(state.range?.end)).format("YYYY-MM-DD")
        : null;

      try {
        if (state.type == null) return;

        isDataLoading.value = true;
        state.data = [];
        const { data } = await getAnalytics(state.type, date_from, date_to);

        if (state.type == "kpi") {
          const transformedData = Object.keys(data).map((key) => ({
            name: key,
            pl: data[key].kpi,
          }));
          state.data = transformedData;
        } else if (state.type == "time-consuming") {
          const transformedData = Object.keys(data).map((key) => ({
            name: key,
            pl: data[key],
          }));
          state.data = transformedData;
        } else {
          state.data = data;
        }
      } finally {
        isDataLoading.value = false;
      }
    };

    watch(
      () => [state.type, state.range],
      () => getData()
    );
    onMounted(() => getData());

    return {
      state,
      analyticsPermissionsOptions,
      isDataLoading,
      isPermissionsLoaded,
    };
  },
};
</script>

<style lang="scss" >
.depot__select.long .vs__selected {
  width: 230px;
}
</style>